import { render, staticRenderFns } from "./MyPosts.vue?vue&type=template&id=e2cbe7e4&scoped=true&"
import script from "./MyPosts.vue?vue&type=script&lang=js&"
export * from "./MyPosts.vue?vue&type=script&lang=js&"
import style0 from "./MyPosts.vue?vue&type=style&index=0&id=e2cbe7e4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e2cbe7e4",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
import Ripple from 'vuetify/lib/directives/ripple'
installDirectives(component, {Intersect,Ripple})
